import * as React from "react";
import { Game, Geom, Scene, WEBGL } from "phaser";
import { Math as MathPhaser } from "phaser";
import asset from "../img/beegame.png";
import { ReactComponent as Background } from "../img/bee_bg.svg";
import assetJson from "../img/beegame.json";
import "./bee-game.css";

const d = new Date();
const startTime = d.getTime();

function msToTime(startTime, newTime) {
  let s = newTime - startTime;

  let secs = (s / 1000) % 60;

  let mins = s / 1000 / 60 - 0.5;

  return String(Math.round(mins)) + ":" + String(Math.round(secs));
}

function getRandomInt(max) {
  return MathPhaser.Between(0, max);
}

const eps = 35;
const inverseSpeed = 100;
const DEFAULT_SIZE = 900;
const numberofPlanes = 3;

class BeeScene extends Scene {
  width = DEFAULT_SIZE;
  height = DEFAULT_SIZE;

  constructor(args, callback, { clientWidth, clientHeight }) {
    super(args);

    this.width = clientWidth;
    this.height = clientHeight;

    this.text = null;
    //pass this as callback
    this.onSceneEvent = callback;
    this.planeArray = [];
    this.distanceFromTargetArray = [];
    this.angleWithTargetArray = [];
    this.targetXArray = [];
    this.targetYArray = [];
    this.planHasBeenHit = [];
    for (let i = 0; i < numberofPlanes; i++) {
      this.targetXArray.push(MathPhaser.Between(0, this.width));
      this.targetYArray.push(MathPhaser.Between(0, this.height));
    }

    this.score = 0;
    this.errors = 0;
  }

  isGameFinished() {
    if (this.score < numberofPlanes) return false;

    this.onSceneEvent({
      thymia_event_type: "game_over",
      points: this.score * 100 - this.errors * 25,
      errors: this.errors,
    }); //TODO use phaser time
    return true;
  }

  preload() {
    // this.load.atlas('sheet', 'img/sheet.png', 'img/sheet.img');
    this.load.atlas("beegame", asset, assetJson);
    this.onSceneEvent({ thymia_event_type: "assets_loaded" }); //TODO use phaser time
  }

  create() {
    const onSceneEvent = this.onSceneEvent;

    this.anims.create({
      key: "neutral",
      repeat: -1,
      frameRate: 10,
      //frames: this.anims.generateFrameNames('sheet', { start: 1,  end: 3, prefix: 'planeBlue', suffix: '.png' })
      frames: this.anims.generateFrameNames("beegame", {
        start: 1,
        end: 6,
        prefix: "bee/neutral_",
        suffix: ".png",
      }),
    });
    //WAB_Thymia_Happy Bee_Sprite-2.png
    this.anims.create({
      key: "happy_bee",
      repeat: 0,
      frameRate: 10,
      //frames: this.anims.generateFrameNames('sheet', { start: 1,  end: 3, prefix: 'planeBlue', suffix: '.png' })
      frames: this.anims.generateFrameNames("beegame", {
        start: 1,
        end: 6,
        prefix: "bee/happy_",
        suffix: ".png",
      }),
    });

    this.anims.create({
      key: "angry_bee",
      repeat: 0,
      frameRate: 10,
      //frames: this.anims.generateFrameNames('sheet', { start: 1,  end: 3, prefix: 'planeBlue', suffix: '.png' })
      frames: this.anims.generateFrameNames("beegame", {
        start: 1,
        end: 6,
        prefix: "bee/angry_",
        suffix: ".png",
      }),
    });

    this.anims.create({
      key: "sad_bee",
      repeat: 0,
      frameRate: 10,
      //frames: this.anims.generateFrameNames('sheet', { start: 1,  end: 3, prefix: 'planeBlue', suffix: '.png' })
      frames: this.anims.generateFrameNames("beegame", {
        start: 1,
        end: 6,
        prefix: "bee/sad_",
        suffix: ".png",
      }),
    });
    //this.bg = this.add.tileSprite(0, 0, width, height, 'sheet', 'background.png').setOrigin(0);
    //this.bg = this.add.tileSprite(0, 0, width, height, 'beegame', 'background.png').setOrigin(0);
    this.scoreUp = this.add.image(100, 50, "beegame", "ui/score_up.png");
    this.scoreDown = this.add.image(
      110 + 100,
      50,
      "beegame",
      "ui/score_down.png"
    );
    this.timer = this.add.image(230 + 100, 50, "beegame", "ui/timer.png");

    this.input.on("pointerup", function (pointer) {
      const duration = pointer.upTime - pointer.downTime;

      const x = pointer.x;
      const y = pointer.y;
      const ev_log = {
        thymia_event_type: "canvas_pointerup",
        x: x,
        y: y,
        game_time: pointer.upTime,
        duration: duration,
      };
      onSceneEvent(ev_log);
    });

    //  Debug output:
    // this.text = this.add.text(10, 10, 'Hit All Planes only Once', {font: '16px Courier', fill: '#00ff00'});
    this.scoreUpText = this.add.text(100 + 20, 35, this.score, {
      font: "24px Roboto",
      fill: "#8069D8",
    });
    this.scoreDownText = this.add.text(110 + 100 + 20, 35, this.errors, {
      font: "24px Roboto",
      fill: "#8069D8",
    });
    this.timerText = this.add.text(
      230 + 100 - 30,
      35,
      msToTime(startTime, startTime),
      { font: "24px Roboto", fill: "#8069D8" }
    );

    for (let i = 0; i < numberofPlanes; i++) {
      // this.planeArray.push(this.add.sprite(200, 150, 'sheet').play('plane'));
      const aBee = this.add.sprite(100, 100, "beegame");
      aBee.setScale(0.3, 0.3);
      aBee.play("neutral");
      this.planeArray.push(aBee);
      this.distanceFromTargetArray.push(
        MathPhaser.Distance.Between(
          this.planeArray[i].x,
          this.planeArray[i].y,
          this.targetXArray[i],
          this.targetYArray[i]
        )
      );
      this.angleWithTargetArray.push(
        MathPhaser.Angle.Between(
          this.planeArray[i].x,
          this.planeArray[i].y,
          this.targetXArray[i],
          this.targetYArray[i]
        )
      );
      this.planHasBeenHit.push(false);
      this.planeArray[i].hitAlready = false;
      this.planeArray[i].name = "plane_" + i;
      //this.planeArray[i].setInteractive(new Geom.Circle(35, 35, 70), Geom.Circle.Contains);
      this.planeArray[i].setInteractive(
        new Geom.Circle(350, 350, 110),
        Geom.Circle.Contains
      );
      //this.planeArray[i].setInteractive();
    }

    for (let i = 0; i < numberofPlanes; i++) {
      let plane = this.planeArray[i];
      plane.on("pointerup", (pointer) => {
        const duration = pointer.upTime - pointer.downTime;
        const x = pointer.x;
        const y = pointer.y;
        const name = plane.name;
        const isNewPlane = !plane.hitAlready;
        // console.log("you hit me: " + name);
        if (isNewPlane) {
          //  this.text.setText('Great, you hit a new plane!');
          this.score += 1;
          this.scoreUpText.setText(this.score);
          plane.hitAlready = true;
          plane.play("happy_bee");
        } else {
          //     this.text.setText('Oh no! You hit this one already!');
          const ranAnim = getRandomInt(1);
          this.errors += 1;
          this.scoreDownText.setText(this.errors);
          ranAnim === 0 ? plane.play("sad_bee") : plane.play("angry_bee");
        }

        const ev_log = {
          thymia_event_type: "bee_pointerup",
          x: x,
          y: y,
          game_time: pointer.upTime,
          duration: duration,
          plane_id: name,
          is_new_plane: isNewPlane,
        };
        onSceneEvent(ev_log);
      });
      plane.on(
        "animationcomplete",
        function (anim, frame) {
          // console.log(anim.key + " " + frame);
          this.emit("animationcomplete_" + anim.key, anim, frame);
        },
        plane
      );

      plane.on("animationcomplete_happy_bee", () => {
        // console.log("happy complete!");
        plane.play("neutral");
      });

      plane.on("animationcomplete_sad_bee", () => {
        // console.log("sad complete!");
        plane.play("neutral");
      });

      plane.on("animationcomplete_angry_bee", () => {
        // console.log("angry complete!");
        plane.play("neutral");
      });
    }
    this.onSceneEvent({ thymia_event_type: "game_start" }); //TODO use phaser time
  }

  update(time, delta) {
    if (!this.isGameFinished(this.planeArray)) {
      this.timerText.setText(msToTime(startTime, new Date().getTime()));

      for (let i = 0; i < numberofPlanes; i++) {
        this.distanceFromTargetArray[i] = MathPhaser.Distance.Between(
          this.planeArray[i].x,
          this.planeArray[i].y,
          this.targetXArray[i],
          this.targetYArray[i]
        );
      }

      for (let i = 0; i < numberofPlanes; i++) {
        if (this.distanceFromTargetArray[i] > eps) {
          //rotate towards target
          // this.angleWithTargetArray[i] = Math.Angle.Between(this.planeArray[i].x, this.planeArray[i].y, this.targetXArray[i], this.targetYArray[i]);
          // this.planeArray[i].rotation += (this.angleWithTargetArray[i] - this.planeArray[i].rotation) / rotationInverseSpeed;
          //move one step
          if (this.targetXArray[i] - this.planeArray[i].x > 0) {
            this.planeArray[i].setFlipX(true);
          } else {
            this.planeArray[i].resetFlip();
          }

          this.planeArray[i].x +=
            (this.targetXArray[i] - this.planeArray[i].x) / inverseSpeed;
          this.planeArray[i].y +=
            (this.targetYArray[i] - this.planeArray[i].y) / inverseSpeed;
        } else {
          this.targetXArray[i] = MathPhaser.Between(0, this.width);
          this.targetYArray[i] = MathPhaser.Between(0, this.height);
        }
      }
    } else {
      this.scoreUpText.setText(this.score);
    }
  }
}

class BeeGame extends React.Component {
  state = {
    phaserGame: null,
  };

  constructor(props) {
    super(props);
    this.onGameEvent = this.onGameEvent.bind(this);
    this.passEvent = this.passEvent.bind(this);
  }

  passEvent(callbackName, ev) {
    if (this.props[callbackName]) {
      this.props[callbackName](ev);
    }
  }

  onGameEvent(ev) {
    const event_type = ev.thymia_event_type;
    if (event_type === "game_over") {
      this.passEvent("onFinish", ev);
      this.state.phaserGame.destroy();
    } else if (event_type === "canvas_pointerup") {
      if (ev.x < 100 && ev.y < 100) {
        this.passEvent("onError", new Error("OUT OF SCOPE!"));
      } else {
        this.passEvent("onClick", ev);
      }
    } else if (event_type === "bee_pointerup") {
      this.passEvent("onEvent", ev);
      this.passEvent("onMessage", "Bravo! You clicked Bee!");
    } else {
      this.passEvent("onEvent", ev);
    }
  }

  componentDidMount() {
    const rootElement = document.getElementById(this.props.rootElementId);
    const clientWidth = rootElement.clientWidth || DEFAULT_SIZE;
    const clientHeight = rootElement.clientHeight || DEFAULT_SIZE;

    const config = {
      type: WEBGL,
      autoResize: true,
      parent: "phaser-game",
      width: clientWidth,
      height: clientHeight,
      scene: [
        new BeeScene(null, this.onGameEvent, { clientWidth, clientHeight }),
      ],
      transparent: true,
    };

    const myGame = new Game(config);

    this.setState({ phaserGame: myGame });
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div className="bee-game-container">
        <Background preserveAspectRatio="xMidYMid slice" />
        <div className="bee-phaser-container" id="phaser-game" />
      </div>
    );
  }
}

export default BeeGame;
