import React from "react";
import ReactDOM from "react-dom";
import BeeGame from "./components/bee-game";

window.renderGame = (
  rootElementId,
  onClick,
  onEvent,
  onMessage,
  onFinish,
  onError
) => {
  console.log("MOUNT GAME");
  ReactDOM.render(
    <BeeGame
      onClick={onClick}
      onEvent={onEvent}
      onMessage={onMessage}
      onFinish={onFinish}
      onError={onError}
      rootElementId={rootElementId}
    />,
    document.getElementById(rootElementId)
  );
};

window.unmountGame = (rootElementId) => {
  console.log("UNMOUNT GAME");
  ReactDOM.unmountComponentAtNode(document.getElementById(rootElementId));
};

window.dispatchEvent(new Event("game-ready"));
